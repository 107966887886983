import React from 'react'
import { Link } from 'gatsby'

const styledP = "text-xl md:text-2xl text-white font-medium leading-relaxed mb-12"

const HomeCommercialLaundrySection = () => (
  <div id='commercial-laundry' className="bg-ff-blue-10">
    <div className="w-11/12 md:w-full xl:max-w-screen-xl mx-auto py-24">
      <div className="flex flex-wrap text-center justify-center">
        <div className="w-full md:w-10/12 lg:w-8/12">
          <h2 className="text-5xl md:text-6xl text-white font-bold uppercase leading-tight mb-12">
            Commercial Laundry
          </h2>

          <p className={styledP}>
            We provide reliable and efficient laundry solutions tailored to meet the needs of various businesses. Whether you're a restaurant, coffee shop, salon or gym, we can manage your linens, towels, and uniforms with care. Reach out to us today for a complimentary quote and discover how we can support your operations.
          </p>

          <Link to='/commercial-laundry'>
            <button className="bg-transparent hover:bg-white text-white hover:text-ff-blue-9 text-lg md:text-xl lg:text-3xl uppercase font-bold border-4 border-white hover:border-ff-blue-10 rounded-full py-4 px-12 md:px-8">
              Learn More
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default HomeCommercialLaundrySection